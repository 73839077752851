// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bl_kw";
export var caseStudyBackground__lineColor = "bl_kt";
export var caseStudyHead = "bl_kn";
export var caseStudyHead__imageWrapper = "bl_kp";
export var caseStudyProjectsSection = "bl_kx";
export var caseStudyQuote__bgLight = "bl_kD";
export var caseStudyQuote__bgRing = "bl_kr";
export var caseStudyVideo__ring = "bl_kG";
export var caseStudy__bgDark = "bl_km";
export var caseStudy__bgLight = "bl_kl";